import { ReactElement } from 'react';

// next
import { NextPageContext } from 'next';
import { getCsrfToken, getProviders } from 'next-auth/react';

// material-ui

// project import
import Page from 'components/Page';
import Layout from 'layout';
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthLogin from 'sections/auth/auth-forms/AuthLogin';

export default function SignIn({ providers, csrfToken }: any) {
  return (
    <Page title="Login">
      <AuthWrapper>
        <AuthLogin providers={providers} csrfToken={csrfToken} />
      </AuthWrapper>
    </Page>
  );
}

SignIn.getLayout = function getLayout(page: ReactElement) {
  return <Layout variant="auth">{page}</Layout>;
};

export async function getServerSideProps(context: NextPageContext) {
  const providers = await getProviders();
  const csrfToken = await getCsrfToken(context);

  return {
    props: { providers, csrfToken }
  };
}
