// material-ui
import { Box, CardMedia } from '@mui/material';
import { Theme } from '@mui/material/styles';

// project import
import MainCard, { MainCardProps } from 'components/MainCard';
// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //
const image = '/assets/images/login/login.svg';

const AuthCard = ({ children, imageLogo, ...other }: MainCardProps & { imageLogo?: string }) => (
  <MainCard
    sx={{
      maxWidth: { xs: 400, lg: 980 },
      margin: { xs: 2.5, md: 3 },
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'column', md: 'row' },
      alignItems: 'center'
    }}
    content={false}
    {...other}
    border={false}
    boxShadow
    shadow={(theme: Theme) => theme.customShadows.z1}
  >
    <CardMedia
      sx={{
        maxHeight: { xs: '100%', md: '490px' }
      }}
      component="img"
      image={imageLogo ?? image}
      title="auth"
    />
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, mt: 2 }}>{children}</Box>
  </MainCard>
);

export default AuthCard;
